import React, { useState, useEffect } from "react";
import {
  DeleteBill,
  getBill,
  getBillFileUrl,
} from "../../services/Currency.service";
import "./OutflowBill.css";
import OutflowSplitbill from "../OutflowSplitbill/OutflowSplitbill";
import Spinner from "../../components/Spinner/Spinner";
import {
  Avatar,
  Button,
  Dialog,
  DialogTitle,
  IconButton,
  Paper,
} from "@mui/material";
import OutflowForm from "../OutflowForm/OutflowForm";
import Close from "@mui/icons-material/Close";
import SnackbarMsg from "../../components/superadmin-home/components/Common/Snackbar";
import DeleteModal from "../DeleteModal/DeleteModal";
import { xeroIcon } from "../../assest";
import { fetchApi } from "../../services/api.service";
import SendToXero from "./SendToXero/SendToXero";

function OutflowFormDialog(props) {
  const { onClose, open, callBillApi, setCallBillApi } = props;

  const handleClose = () => {
    onClose(false);
  };

  return (
    <Dialog onClose={handleClose} open={open} maxWidth={"lg"}>
      <DialogTitle>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div>Enter Bill</div>
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </div>
      </DialogTitle>
      <OutflowForm handleClose={handleClose} callBillApi={callBillApi} setCallBillApi={setCallBillApi} />
    </Dialog>
  );
}

const OutflowBill = ({ user }) => {
  const [bill, setBill] = useState([]);
  const [splitButtonVisible, setSplitButtonVisible] = useState(true);
  const [selectedSplit, setSelectedSplit] = useState(-1);
  const [isLoading, setIsLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false)
  const [apiMsg, setApiMsg] = useState(null)
  const [MsgColor, setMsgColor] = useState(null)
  const [callBillApi, setCallBillApi] = useState(false)
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
  const [deleteItemId, setDeleteItemId] = useState(null)
  const [isDeleteSubmit, setIsDeleteSubmit] = useState(false)
  const [openXeroConfModal, setOpenXeroConfModal] = useState(false)
  const [xeroConfData, setXeroConfdata] = useState(null)

  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = (value) => {
    setOpen(false);
  };

  const handleGetBillFile = async (bill) => {
    const url = await getBillFileUrl(bill.id);
    if (url.message) {
      setOpenSnackbar(true)
      setApiMsg(url.message);
      setMsgColor('red')
      setTimeout(() => { setOpenSnackbar(false) }, [1500])
    }
    else if (url) { window.open(url, "_blank"); }
  };

  const handleSplitFlag = (idx) => {
    setSplitButtonVisible(false);
    setSelectedSplit(idx);
  };

  const fetchBill = async () => {
    setIsLoading(true);
    let bills = await getBill();
    setBill(bills.palyload);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchBill();
  }, [callBillApi]);

  // open delete pop-up
  const handleDeletePopUp = (id) => {
    setOpenDeleteModal(true)
    setDeleteItemId(id)
  }

  const handleClosePopUp = () => {
    setOpenDeleteModal(false)
  }

  const handledeleteInvoice = async () => {
    setIsDeleteSubmit(true)
    const result = await DeleteBill(deleteItemId);
    if (result) {
      setApiMsg(result?.message || result?.err)
      setMsgColor(result.success ? 'green' : 'red')
      setOpenSnackbar(true)
      fetchBill();
      setTimeout(() => { setOpenSnackbar(false) }, [1500])
      setIsDeleteSubmit(false)
      setOpenDeleteModal(false)
    }
  };

  // open send to xero confirmation modal
  const handleOpenXeroConfModal = (elem) => {
    setOpenXeroConfModal(true);
    setXeroConfdata(elem);
  }

  // function to handle close confirmation modal
  const handleCloseXeroConfModal = () => {
    setOpenXeroConfModal(false);
  }

  const isBillStatusC = bill?.filter((item) => item?.status === "C")

  return (
    <>
      <div>
        <div className="outflowbill-button-searchbox">
          <Button
            sx={{ fontSize: "0.6rem" }}
            variant="contained"
            size="small"
            onClick={handleClickOpen}
          >
            Enter Bill
          </Button>
        </div>

        <OutflowFormDialog open={open} onClose={handleClose} callBillApi={callBillApi} setCallBillApi={setCallBillApi} />

        {isLoading ? (
          <Spinner />
        ) : (
          <>
            {isBillStatusC?.length > 0 ? isBillStatusC?.map((element, index) => {
              const backgroundColor = index % 2 === 0 ? '#F8F8F8' : 'white'
              return (
                <Paper key={index} className="outflowbill-container-box" style={{ backgroundColor }}>
                  <div className="table-details-bill">
                    <div className="table-details-bill-left">
                      <div
                        onClick={() => handleGetBillFile(element)}
                        className="outflowbill-invoice"
                      >
                        <span className="pdfLogo-img">
                          <img
                            src="https://cdn.iconscout.com/icon/free/png-256/free-pdf-file-20-504245.png"
                            alt=""
                          />
                        </span>
                        <p className="outflow-invoice-number">
                          {element.invoice_no}
                        </p>
                      </div>
                    </div>
                    <div className="table-details-bill-right">
                      <div className="field-col">
                        <div className="field">
                          <p className="tags">From:&nbsp;</p>
                          <p className="tags1">{element.vendor.name}</p>
                        </div>
                        <div className="field">
                          <p className="tags">Total:&nbsp;</p>
                          <p className="tags1">{element.currency}&nbsp;</p>
                          <p className="tags1">{element.total_amt}</p>
                        </div>
                      </div>
                      <div className="field-col">
                        <div className="field">
                          <p className="tags">PO Amount:&nbsp;</p>
                          <p className="tags1">{element.po_amount || "NA"}</p>
                        </div>
                        <div className="field">
                          <p className="tags">To:&nbsp;</p>
                          <p className="tags1">{element.organisation.name}</p>
                        </div>
                      </div>
                      <div className="field-col">
                        <div className="field">
                          <p className="tags">Sub-Total:&nbsp;</p>
                          <p className="tags1">{element.currency}&nbsp;</p>
                          <p className="tags1">{element.subtotal}</p>
                        </div>
                        <div className="field">
                          <p className="tags" >PO Number:&nbsp;</p>
                          <p className="tags1">{element.po_no || "NA"}</p>
                        </div>
                      </div>
                      <div className="field-col">
                        <div className="field">
                          <p className="tags">GST:&nbsp;</p>
                          <p className="tags1">{element.currency}&nbsp;</p>
                          <p className="tags1">{element.total_gst || "NA"}</p>
                        </div>
                      </div>
                    </div>
                  </div>

                  {splitButtonVisible || selectedSplit !== index ? (
                    <div className="outflowbill-main-button-box">
                      <div>
                        <Button sx={{
                          fontSize: "0.6rem",
                        }}
                          size="small"
                          variant="text"
                          onClick={() => handleDeletePopUp(element.id)}
                        >
                          Delete
                        </Button>
                      </div>
                      <div>
                        <Button variant="outlined" onClick={() => handleOpenXeroConfModal(element)} endIcon={<Avatar src={xeroIcon} sx={{ width: 20, height: 20 }} />} sx={{
                          fontSize: "0.6rem",
                        }}
                          size="small">
                          Send to Xero
                        </Button>
                      </div>
                      <div>
                        <Button
                          variant="contained"
                          sx={{ fontSize: "0.6rem" }}
                          size="small"
                          onClick={() => handleSplitFlag(index)}
                        >
                          Split
                        </Button>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <OutflowSplitbill
                        gst={element.total_gst}
                        invoice_id={element.id}
                        amount={element.subtotal}
                        GL_code={element.outflowbill_products[0].gl_code}
                        currency={element.currency}
                        handleSplitFlag={handleSplitFlag}
                      />
                    </div>
                  )}
                </Paper>
              );
            }) : <div style={{ textAlign: 'center', fontWeight: "500" }}>No bill available</div>}
          </>
        )}
        <SnackbarMsg apiMsg={apiMsg} open={openSnackbar} color={MsgColor} />
      </div>
      {openDeleteModal && <DeleteModal openDeleteModal={openDeleteModal} handleClosePopUp={handleClosePopUp} handleDelete={handledeleteInvoice} isDeleteSubmit={isDeleteSubmit} />}
      {openXeroConfModal && <SendToXero data={xeroConfData} openXeroConfModal={openXeroConfModal} handleCloseXeroConfModal={handleCloseXeroConfModal} callBillApi={callBillApi} setCallBillApi={setCallBillApi} />}
    </>
  );
};

export default OutflowBill;

