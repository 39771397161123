import React, { useEffect } from "react";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import "./BillProducts.css";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";

const BillProducts = ({ bill, setBill, isTaxExcl }) => {
  const [products, setProducts] = useState([]);

  const addProduct = (e) => {
    e.preventDefault();

    const newProduct = {
      description: null,
      quantity: null,
      price: null,
      tax_rate: null,
    };
    setProducts([...products, newProduct]);
  };

  const handleChange = (idx, event) => {
    const { name, value } = event.target;
    console.log("name, value", name, value)
    const updatedProducts = products.map((product, index) =>
      idx === index ? { ...product, [name]: value } : product
    );
    const taxFirst = updatedProducts[0]?.tax_rate;
    setProducts(
      updatedProducts.map((e) => ({
        ...e,
        tax_rate: taxFirst,
      }))
    );
  };

  // useEffect to update the tax rate value when tax type changes
  useEffect(() => {
    if (!isTaxExcl) {
      const updatedProducts = products.map((product) => ({
        ...product,
        tax_rate: 0,
      }));
      setProducts(updatedProducts);
    }
  }, [isTaxExcl]);

  useEffect(() => {
    setBill({ ...bill, bill_products: products });
  }, [products]);

  // calculating total including tax
  const calculateTotalValue = (quantity = 0, price = 0, taxRate = 0) => {
    const totalPrice = quantity * price;
    const totalTax = (totalPrice * taxRate) / 100;
    return totalPrice + totalTax;
  };


  return (
    <div style={{ margin: "10px 0" }} className="billProduct">
      <Table component={Paper} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell align=""> Description*</TableCell>
            <TableCell align=""> Quantity*</TableCell>
            <TableCell align=""> Price*</TableCell>
            <TableCell align=""> Tax Rate</TableCell>
            {isTaxExcl && <TableCell align=""> Amount</TableCell>}
            <TableCell align=""> Total Amount</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {products.map((element, index) => (
            <TableRow key={element.name}>
              <TableCell align="left">
                <TextField
                  id="outlined-basic"
                  size="small"
                  type="text"
                  name="description"
                  inputProps={{
                    maxlength: 2000,
                    style: { fontSize: "12px", height: 12, width: "100%" },
                  }}
                  value={element.description}
                  required
                  onChange={(event) => handleChange(index, event)}
                />
              </TableCell>
              <TableCell align="left">
                <TextField
                  id="outlined-basic"
                  size="small"
                  type="number"
                  required
                  name="quantity"
                  inputProps={{ min: 0, step: 1, style: { fontSize: "12px", height: 12 } }}
                  value={element.quantity}
                  onChange={(event) => {
                    if (event.target.value.length <= 10) {
                      handleChange(index, event);
                    }
                  }}
                />
              </TableCell>
              <TableCell align="left">
                <TextField
                  id="outlined-basic"
                  size="small"
                  required
                  type="number"
                  name="price"
                  inputProps={{ min: 0, maxlength: 10, step: 0.01, style: { fontSize: "12px", height: 12 } }}
                  value={element.price}
                  onChange={(event) => {
                    if (event.target.value.length <= 10) {
                      handleChange(index, event);
                    }
                  }}
                />
              </TableCell>
              <TableCell align="left">
                <TextField
                  id="outlined-basic"
                  size="small"
                  required
                  type="number"
                  name="tax_rate" style={{ background: !isTaxExcl ? "#f4f4f4" : "" }}
                  disabled={index > 0 || !isTaxExcl}
                  inputProps={{ style: { fontSize: "12px", height: 12 } }}
                  value={isTaxExcl === true ? element.tax_rate : ''}
                  onChange={(event) => handleChange(index, event)}
                />
              </TableCell>
              {isTaxExcl && <TableCell align="left">
                <TextField
                  id="outlined-basic"
                  size="small"
                  required
                  type="number"
                  name="amount"
                  inputProps={{ style: { fontSize: "12px", height: 12, background: '#f4f4f4' } }}
                  value={parseFloat((element.quantity * element.price).toFixed(2))}
                />
              </TableCell>}
              <TableCell align="left">
                <TextField
                  id="outlined-basic"
                  size="small"
                  required
                  type="number"
                  name="total_amount"
                  inputProps={{ style: { fontSize: "12px", height: 12, background: '#f4f4f4' } }}
                  // value={Number(element.quantity * element.price) + Number(((element.quantity * element.price) * element.tax_rate) / 100)}
                  value={calculateTotalValue(element.quantity, element.price, element.tax_rate)}
                  onChange={(event) => handleChange(index, event)}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <div>
        <FontAwesomeIcon
          icon={faPlusCircle}
          onClick={addProduct}
          style={{
            color: "#1976d2",
            height: "30px",
            margin: "15px 0",
            display: "flex",
            justifyContent: "flex-start",
          }}
        />
      </div>
    </div >
  );
};

export default BillProducts;