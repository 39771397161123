import React, { useContext, useEffect, useState } from "react";
import "./Content.css";
import OutflowBill from "../../pages/OutflowBill/OutflowBill";
import { NewXero } from "../../pages/NewXero/NewXero";
import NewApprovals from "../../pages/NewApprovals/NewApprovals";
import DashboardNew from "../../pages/DashboardNew/DahsboardNew";
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import UnpublishedIcon from '@mui/icons-material/Unpublished';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import DashboardIcon from '@mui/icons-material/Dashboard';
import { Badge, Paper } from "@mui/material";
import { xeroGreyIcon, xeroIcon } from "../../assest";
import GlobalContext from "../../pages/TimesheetContext/GlobalContext";
import DirectorRefusedInvoices from "../../pages/DirectorRefusedInvoices/DirectorRefusedInvoices";
import { getBill, getSplitbillData } from "../../services/Currency.service";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ padding: "0 10px", height: "75vh", overflowY: "auto" }}>
          {children}
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

/// getting user's mapped organisation id array
const userData = sessionStorage.getItem("user")
const userObject = JSON.parse(userData);

function Content({ user }) {
  const [allBills, setAllBills] = useState([])
  const [outflowBillLength, setOutflowBillLength] = useState(null)
  const [refusedBillLength, setRefusedBillLength] = useState(null)
  const [apprBillLength, setApprBillLength] = useState(null)
  const { value, setValue } = useContext(GlobalContext);

  const fetchBill = async () => {
    let bills = await getBill();
    setAllBills(bills?.palyload);
    let outFlowCount = bills?.palyload.filter((item) => item.status === 'C')
    setOutflowBillLength(outFlowCount)
    let outflApprBill = bills?.palyload.filter((item) => item.status === 'AA')
    let ApprovedBill = bills?.palyload.filter((item) => item.status === 'AP')
    setApprBillLength(outflApprBill?.length + ApprovedBill?.length)
  };

  const fetchsplitBill = async () => {
    let bills = await getSplitbillData();
    // console.log("splitBill", bills)
    const RefusedBills=bills?.filter((x)=>x?.bill.status==='R')
    /// calculating count of the refused bills with mapped organisatio only
    const calLength = bills?.reduce((acc, item) => {
      if (
        item?.bill?.status === "R" &&
        item?.org_splits?.some((split) => userObject?.orgs?.includes(split?.organisation?.id))
      ) {
        return acc + 1;
      }
      return acc;
    }, 0);
    setRefusedBillLength(calLength)
  };
  useEffect(() => {
    fetchBill();
    fetchsplitBill();
  }, [value]);


  const tabData = [
    { label: "Outflow", icon: <RequestQuoteIcon />, comp: <OutflowBill user={user} />, index: 0, length: outflowBillLength?.length },
    { label: 'Approvals', icon: <CheckCircleIcon />, comp: <NewApprovals user={user} />, index: 1, length: apprBillLength },
    { label: 'Refused Invoices', icon: <UnpublishedIcon />, comp: <DirectorRefusedInvoices user={user} />, index: 2, length: refusedBillLength },
    // { label: 'Xero', comp: <NewXero />, index: 3, logo: xeroIcon },
    { label: 'Dashboard', icon: <DashboardIcon />, comp: <DashboardNew user={user} />, index: 4 }
  ];

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="content-mainDiv">
      <Paper className="content-container">
        <Box>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs
              value={value}
              onChange={handleChange}
              variant="fullWidth"
              sx={{ height: "3rem", display: "flex", justifyContent: "center", alignItems: "center" }}
            >
              {tabData.map((tab, index) => (
                <Tab
                  user={user}
                  key={index}
                  iconPosition="start"
                  icon={tab.icon ? tab.icon : <img src={value === 3 ? xeroIcon : xeroGreyIcon} alt="tab-icon" width={22} height={22} />}
                  label={<>
                    <h3 style={{ fontSize: "0.7rem", fontWeight: "bold" }}>{tab.label}<span>&nbsp;{tab?.length > 0 ? `(${tab.length})` : null}</span></h3>
                  </>}
                  {...a11yProps(index)}
                />
              ))}
            </Tabs>
          </Box>
        </Box>
        {tabData.map((tab, index) => (
          <CustomTabPanel key={index} value={value} index={tab.index} user={user}>
            {tab.comp}
          </CustomTabPanel>
        ))}
      </Paper>
    </div>
  );
}

export default Content;

