import React, { useContext, useEffect, useState } from "react";
import {
  DirectorApproves,
  GetUsers,
  getBillFileUrl,
  getSplitbillData,
} from "../../services/Currency.service";
import "./DirectorApprovalBill.css";
import Spinner from "../../components/Spinner/Spinner";
import { Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { Box } from "@mui/system";
import GlobalContext from "../TimesheetContext/GlobalContext";
import RefusalDialogForm from "../RefusalDialogForm/RefusalDialogForm";
import LoadingButton from "@mui/lab/LoadingButton";
import DirectorbillApproved from "../DirectorbillApproved/DirectorbillApproved";
import AccessAlarmsIcon from '@mui/icons-material/AccessAlarms';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const DirectorApprovalBill = ({ user }) => {
  const [splitbill, setSplitbill] = useState([]);
  const [companyName, setCompanyName] = useState([]);
  const [users, setUsers] = useState([]);
  const [cuAllCom, setCUAllCom] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [refusedIndex, setRefusedIndex] = useState("")
  const [isSubmit, setIsSubmit] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false)
  const [apiMsg, setApiMsg] = useState(null)
  const [openLoader, setOpenLoader] = useState(false)
  const { openTimeSheetModal, setOpenTimeSheetModal, setSelectedComponent } = useContext(GlobalContext)

  const handleGetBillFile = async (billId) => {
    const url = await getBillFileUrl(billId);
    if (url) window.open(url, "_blank");
  };

  const fetchsplitBill = async () => {
    setIsLoading(true);
    let bills = await getSplitbillData();

    // Filter bills
    const userOrgs = user?.orgs
    bills = bills?.filter(b => {
      // Find all the splits that involve at least one of my orgs.
      const mySplits = b?.org_splits?.filter(sp => userOrgs?.includes(sp?.organisation?.id))
      // console.log(mySplits,'my splits length')
      if (mySplits?.length) {
        // Of my splits, find at least one split that has not been approved by me yet.
        const findNonApproved = mySplits?.find(ms => ms?.dir_approval_status === 'DS')
        // If none could be found (i.e. all my splits have been approved), exclude this bill from the list.
        if (!findNonApproved) return false
      }
      // Display for everyone else.
      return true
    })

    setSplitbill(bills);
    setIsLoading(false);
    return bills;
  };

  useEffect(() => {
    if (Object.keys(users).length)
      fetchsplitBill().then((bills) => {
        handleHighlighting(bills);
      });
  }, [users]);

  useEffect(() => {
    getAllUsers();
  }, []);


  const getAllUsers = async () => {
    setUsers(await GetUsers());
  };

  const handleHighlighting = async (bills) => {
    let [currentUserAllCompanies = []] = users
      .filter((e) => e?.email_id === user?.email)
      .map((e) => e?.organisations.map((e) => e?.name));

    setCUAllCom([...currentUserAllCompanies]);

    const allCompaniesPresentInThePage = new Set();
    for (let k = 0; k < bills?.length; k++) {
      for (let l = 0; l < bills[k].org_splits?.length; l++) {
        allCompaniesPresentInThePage.add(
          bills[k].org_splits[l].organisation.name
        );
      }
    }
    const allCompaniesOfUserInThePage = [
      ...allCompaniesPresentInThePage,
    ].filter((e) => currentUserAllCompanies.includes(e));
    setCompanyName([...allCompaniesOfUserInThePage]);
  };

  const isAllDA = (orgSplit) => {
    let flag = false;
    for (let i = 0; i < orgSplit?.length; i++) {
      // console.log(orgSplit[i].dir_approval_status);
      if (orgSplit[i].dir_approval_status === "DA") {
        flag = true;
      } else {
        flag = false;
        break;
      }
    }
    return flag;
  };

  const isAllDS = (orgSplit) => {
    let flag = false;
    for (let i = 0; i < orgSplit.length; i++) {
      // console.log(orgSplit[i].dir_approval_status);
      if (orgSplit[i].dir_approval_status === "DS") {
        flag = true;
      } else {
        flag = false;
        break;
      }
    }
    return flag;
  };

  const noOrgDir = (orgSplit) => {
    // console.log(user.email);
    let flag = false;
    for (let i = 0; i < orgSplit?.length; i++) {
      if (
        !cuAllCom.includes(orgSplit[i].organisation.name) &&
        orgSplit[i]?.dir_approval_status === "DS"
      ) {
        flag = true;
        break;
      } else {
        flag = false;
        // break;
      }
    }
    return flag;
  };

  const handleView = (orgSplit) => {
    let flag = false;
    if (isAllDA(orgSplit)) {
      flag = false;
      return flag;
    }
    // console.log("test");
    if (noOrgDir(orgSplit)) {
      flag = true;
      return flag;
    }

    for (let i = 0; i < orgSplit?.length; i++) {
      if (
        orgSplit[i]?.dir_approval_status === "DS" &&
        cuAllCom.includes(orgSplit[i]?.organisation.name)
      ) {
        flag = true;
        break;
      }
    }
    return flag;
  };

  const handleApprove = async (index) => {
    setIsSubmit(true)
    setOpenLoader(true)
    const USERS = users;
    let company = [];
    for (let i = 0; i < USERS.length; i++) {
      if (user.email === USERS[i].email_id) {
        company = USERS[i].organisations;
        // console.log(company);
      }
    }
    const company_id = [];
    const company_name = [];
    for (let j = 0; j < company.length; j++) {
      for (let k = 0; k < splitbill[index].org_splits.length; k++) {
        if (
          company[j].name === splitbill[index].org_splits[k].organisation.name
        ) {
          company_id.push(splitbill[index].org_splits[k].id);
          company_name.push(splitbill[index].org_splits[k].organisation.name);
        }
      }
    }

    // console.log("abc ", company_name);
    for (let z = 0; z < company_id.length; z++) {
      // console.log(splitbill[index].id, company_id[z]);
      await DirectorApproves(splitbill[index].id, company_id[z]);
    }

    // navigate("/home/directorbillapproved");
    setIsSubmit(false)
    const result = await getAllUsers();
    setOpenSnackbar(true)
    setApiMsg(result?.message)
    setTimeout(() => {
      setOpenSnackbar(false)
    }, 1500);
    setSelectedComponent(<DirectorbillApproved user={user} />)
  };

  const refusedObjData = {
    dirid: user?.id,
    del_splitbill_id: splitbill[refusedIndex]?.id,
    isRejected: splitbill[refusedIndex]?.bill?.isRejectedFirstTime,
  }

  const handleRefuse = () => {
    setOpenTimeSheetModal(true);
  };

  const visibleApprovalBill = splitbill?.filter((element) =>
    handleView(element?.org_splits)
  ) || [];

  const renderedComponentsCount = visibleApprovalBill.length;

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          {renderedComponentsCount > 0 ?
            (
              <>
                {visibleApprovalBill?.map((element, index) => {
                  const backgroundColor = index % 2 !== 0 ? '#F8F8F8' : 'white';
                  const names = new Set(
                    element?.org_splits?.map((e) => e.organisation.name)
                  );
                  const refuseButtonProps = {
                    className: "director-approval-refuse",
                    onClick: () => {
                      handleRefuse(index);
                      setRefusedIndex(index);
                    },
                    style: { cursor: "pointer" },
                  };
                  const approveButtonProps = {
                    className: "director-approval-approve",
                    onClick: () => {
                      handleApprove(index);
                    },
                    style: { cursor: "pointer" },
                  };
                  const isActionPerformable = !!companyName.filter((e) => names.has(e)
                  ).length;
                  if (!isActionPerformable) {
                    refuseButtonProps.className += " disabled";
                    refuseButtonProps.style = { cursor: "default", display: "none" };
                    refuseButtonProps.disabled = true;
                    refuseButtonProps.onClick = () => { };
                    approveButtonProps.className += " disabled";
                    approveButtonProps.onClick = () => { };
                    approveButtonProps.disabled = true;
                    approveButtonProps.style = { ...refuseButtonProps.style };
                  }
                  return handleView(element?.org_splits) ? (
                    <Box key={index} sx={{ margin: "1rem 0" }} component={Paper} style={{ backgroundColor }}>
                      <div className="table-details-bill">
                        <div className="table-details-bill-left">
                          <div className="outflowbill-invoice" onClick={() => handleGetBillFile(element.bill.id)}>
                            <span
                              className="pdfLogo-img">
                              <img
                                src="https://cdn.iconscout.com/icon/free/png-256/free-pdf-file-20-504245.png"
                                alt="" />
                            </span>
                            <p className="outflow-invoice-number">
                              {element.bill.invoice_no}
                            </p>
                          </div>
                        </div>
                        <div className="table-details-bill-right">
                          <div className="right-field-col">
                            <div className="field">
                              <p className="tags">From:&nbsp;</p>
                              <p className="tags1">{element.bill.vendor.name}</p>
                            </div>
                            <div className="field">
                              <p className="tags">Rule:&nbsp;</p>
                              <p className="tags1">{element.rule}</p>
                            </div>
                          </div>
                          <div className="right-field-col">
                            <div className="field">
                              <p className="tags">PO Number:&nbsp;</p>
                              <p className="tags1">{element.bill.po_no || "NA"}</p>
                            </div>
                            <div className="field">
                              <p className="tags">GL Code:&nbsp;</p>
                              <p className="tags1">{element.gl_code || "NA"}</p>
                            </div>
                          </div>
                          <div className="right-field-col">
                            <div className="field">
                              <p className="tags">Sub-Total:&nbsp;</p>
                              <p className="tags1">{element.bill.currency}&nbsp;</p>
                              <p className="tags1">{element.bill.subtotal}</p>
                            </div>
                            <div className="field">
                              <p className="tags">PO Amount:&nbsp;</p>
                              <p className="tags1">${element.bill.po_amount || "NA"}</p>
                            </div>
                          </div>
                          <div className="right-field-col">
                            <div className="field">
                              <p className="tags">GST:&nbsp;</p>
                              <p className="tags1">{element.bill.currency}&nbsp;</p>
                              <p className="tags1">{element.bill.total_gst}</p>
                            </div>
                            <div className="field">
                              <p className="tags">To:&nbsp;</p>
                              <p className="tags1">{element.bill.organisation.name}</p>
                            </div>
                          </div>
                          <div className="right-field-col">
                            <div className="field">
                              <p className="tags">Total:&nbsp;</p>
                              <p className="tags1">{element.bill.currency}&nbsp;</p>
                              <p className="tags1">{element.bill.total_amt}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="director-approval-bill" style={{ padding: "0 0 10px 0 " }}>
                        <TableContainer className="center-table-div" sx={{ '& .MuiTableCell-root': { fontSize: '10px', fontWeight: "600" } }}>
                          <Table size="small" sx={{ margin: "0 8px" }}>
                            <TableHead>
                              <TableRow>
                                <TableCell size="small">Invoice No</TableCell>
                                <TableCell size="small" align="left">Company</TableCell>
                                <TableCell size="small" align="left">Description</TableCell>
                                <TableCell size="small" align="left">Split%</TableCell>
                                <TableCell size="small" align="left">GST</TableCell>
                                <TableCell size="small" align="left">Total(Excluding GST.)</TableCell>
                                <TableCell size="small" align="left">Local(NZD)</TableCell>
                                <TableCell size="small" align="">Status</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {element.org_splits.map((orgSplit, idx) => {
                                return companyName.includes(
                                  orgSplit.organisation.name
                                ) ? (
                                  <TableRow key={idx}>
                                    <TableCell>{orgSplit.split_invoice_no}</TableCell>
                                    <TableCell>{orgSplit.organisation.name}</TableCell>
                                    <TableCell>{orgSplit.description}</TableCell>
                                    <TableCell>{orgSplit.split_per}%</TableCell>
                                    <TableCell sx={{ width: "100px" }} className="td-data">
                                      {orgSplit.gst ? (
                                        <span>&#10003;</span>
                                      ) : (
                                        <>&#88;</>
                                      )}
                                      &nbsp;&nbsp;&nbsp;
                                      <span className="span-data">
                                        {orgSplit.organisation.currency}&nbsp;
                                        {orgSplit.gst_amount}
                                      </span>
                                    </TableCell>
                                    <TableCell>
                                      {orgSplit.organisation.currency}
                                      {orgSplit.payOrganizationAmount}
                                    </TableCell>
                                    <TableCell>{orgSplit.local_nzd}</TableCell>
                                    <TableCell>{orgSplit?.dir_approval_status === "DS" ? <AccessAlarmsIcon sx={{ fontSize: "medium" }} /> : <CheckBoxIcon color={"success"} sx={{ fontSize: "medium" }} />}</TableCell>
                                  </TableRow>
                                ) : (
                                  <TableRow key={idx} sx={{ color: "grey" }}>
                                    <TableCell sx={{ color: "grey" }}>{orgSplit.split_invoice_no}</TableCell>
                                    <TableCell sx={{ color: "grey" }}>{orgSplit.organisation.name}</TableCell>
                                    <TableCell sx={{ color: "grey" }}>{orgSplit.description}</TableCell>
                                    <TableCell sx={{ color: "grey" }}>{orgSplit.split_per}%</TableCell>
                                    <TableCell sx={{ color: "grey", width: "100px" }} className="td-data">
                                      {orgSplit.gst ? (
                                        <span>&#10003;</span>
                                      ) : (
                                        <>&#88;</>
                                      )}
                                      &nbsp;&nbsp;&nbsp;
                                      <span className="span-data">
                                        {orgSplit.organisation.currency}&nbsp;
                                        {orgSplit.gst_amount}
                                      </span>
                                    </TableCell>
                                    <TableCell sx={{ color: "grey" }}>
                                      {orgSplit.organisation.currency}&nbsp;
                                      {orgSplit.payOrganizationAmount}
                                    </TableCell>
                                    <TableCell sx={{ color: "grey" }}>{orgSplit.local_nzd}</TableCell>
                                    <TableCell sx={{ color: "grey" }}>{orgSplit?.dir_approval_status === "DS" ? <AccessAlarmsIcon sx={{ fontSize: "medium" }} /> : <CheckBoxIcon color={"success"} sx={{ fontSize: "medium" }} />}</TableCell>
                                  </TableRow>
                                );
                              })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </div>
                      {isActionPerformable && (
                        <div className="director-approval-buttons" style={{ padding: '10px' }}>
                          {/* <div className="director-approval">Awaiting Approval</div> */}
                          <div>
                            <Button sx={{ fontSize: "0.6rem" }} variant="outlined" size="small" {...refuseButtonProps}>Refuse</Button>
                          </div>
                          <div>
                            <LoadingButton loading={isSubmit} sx={{ fontSize: "0.6rem" }} variant="contained" size="small" {...approveButtonProps}>Approve</LoadingButton>
                          </div>
                        </div>
                      )}
                    </Box>
                  ) : null;
                })}
              </>
            )
            : (<p style={{ textAlign: "center", fontSize: "bold", fontSize: "1rem" }}>No data available</p>)
          }
        </>
      )}

      {openTimeSheetModal && (
        <RefusalDialogForm
          open={openTimeSheetModal}
          setOpenTimeSheetModal={setOpenTimeSheetModal}
          refusedObjData={refusedObjData} />
      )}
      {/* <SnackbarMsg apiMsg={apiMsg} open={openSnackbar} color={"green"} /> */}
    </>

  );
};

export default DirectorApprovalBill;

