import React, { useContext, useState } from "react";
import "../DirectorRefusedInvoices.css";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import GlobalContext from "../../TimesheetContext/GlobalContext";
import { EditBill } from "../../../services/Currency.service";
import Close from "@mui/icons-material/Close";
import SnackbarMsg from '../../../components/superadmin-home/components/Common/Snackbar'
import { validateTaxRates } from "../../../services/api.service";

const EditRefusedInvoice = ({
  visibleSplitBill,
  openEditForm,
  setOpenEditForm,
  editformData,
  setEditFormData,
}) => {
  const [isSubmit, setIsSubmit] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false)
  const [apiMsg, setApiMsg] = useState(null)
  const [MsgColor, setMsgColor] = useState(null)
  const { setValue } = useContext(GlobalContext);

  // calculate GST amount on split % value
  const calSplitItemGST = (totalGST, SplitValue) => {
    if (totalGST) {
      const gstAmt = (totalGST * SplitValue) / 100;
      return gstAmt;
    } else { return 0 }

  };

  // calculate calPayOrganizationAmount on split % value
  const calPayOrganizationAmount = (subTotal, SplitValue) => {
    const amt = (subTotal * SplitValue) / 100;
    return amt;
  };

  // Function to handle input changes\
  const handleChange = (index, field, value) => {
    if (value === null || value === undefined) return;

    // Make a deep copy of the current state
    const newEditData = [...editformData?.split_bill];

    // filtering org_splits items
    const splitItem = visibleSplitBill.filter((i) => i?.org_splits[index]?.split_invoice_no === newEditData[index]?.split_invoice_no);
    const splitItem_subTotal = splitItem[0]?.bill?.subtotal;

    // Update the specific field for the given index
    newEditData[index] = {
      ...newEditData[index], // Ensure immutability
      [field]: value,
    };

    // Calculate GST outside of state update
    const splitItem_gst = splitItem[0]?.bill?.total_gst;

    // calculate GST with new split perecntage value
    const gstAmount = calSplitItemGST(splitItem_gst, newEditData[index].split_per);
    if (gstAmount) {
      newEditData[index]["gst_amount"] = gstAmount;
    };

    // calculate Total(Excluding GST.) which is recOrganizationAmount and payOrganizationAmount with new split perecntage value
    const payOrganizationAmount = calPayOrganizationAmount(splitItem_subTotal, newEditData[index].split_per);
    newEditData[index]['payOrganizationAmount'] = payOrganizationAmount;
    newEditData[index]['recOrganizationAmount'] = payOrganizationAmount;


    setEditFormData({
      ...editformData,
      split_bill: newEditData,
    });
  };

  const CloseEditForm = () => {
    setOpenEditForm(false);
  };

  // Function to handle form submission
  const handleEditSubmit = async () => {
    setIsSubmit(true);

    if (editformData) {
      let taxRateArr = editformData.split_bill.map((item) => item["split_per"]);
      let validationResult = validateTaxRates(taxRateArr);
      if (!validationResult.isValid) {
        setApiMsg(validationResult.message);
        setMsgColor("red")
        setOpenSnackbar(true);
        setTimeout(() => setOpenSnackbar(false), 1500);
        setIsSubmit(false)
        return;
      }

      // calculating sum of the split percentage value (< 100)
      const sumOfSplits = (taxRateArr) => {
        return taxRateArr.reduce((total, current) => {
          return total + parseFloat(current);
        }, 0);
      };
      const totalSum = sumOfSplits(taxRateArr);
      if (totalSum > 100) {
        setApiMsg("some of split % can not be greater than 100");
        setMsgColor("red")
        setOpenSnackbar(true);
        setTimeout(() => setOpenSnackbar(false), 1500);
        setIsSubmit(false)
        return;
      }

      const response = await EditBill(editformData);
      setOpenSnackbar(true)
      // console.log("Form data submitted response:", response);
      return response.success === true
        ? (setIsSubmit(false), setApiMsg(response?.message), setValue(1), setTimeout(() => { CloseEditForm() }, 1500))
        : setApiMsg(response?.message);
    }
  };

  console.log("editformData", editformData)

  return (
    <>
      <Dialog open={openEditForm} onClose={CloseEditForm}>
        <DialogTitle>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div>Enter Bill</div>
            <IconButton onClick={CloseEditForm}>
              <Close />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent>
          <div className="editForm_Content">
            {editformData &&
              editformData?.split_bill?.map((item, index) => {
                return (
                  <div key={index + 10} className="editForm_fieldContent">
                    <div className="editForm_fields">
                      <label>Invoice no:</label>
                      <input readOnly value={item?.split_invoice_no} />
                    </div>
                    <div className="editForm_fields">
                      <label>Split Bill percentage:</label>
                      <input
                        type="number"
                        name="split_per"
                        onChange={(e) =>
                          handleChange(index, "split_per", e.target.value)
                        }
                        value={item?.split_per}
                      />
                    </div>
                  </div>
                );
              })}
          </div>
          <DialogActions>
            <Button
              size="small"
              variant="outlined"
              sx={{ fontSize: "0.6rem" }}
              onClick={CloseEditForm}
            >
              Cancel
            </Button>
            <LoadingButton
              loading={isSubmit}
              onClick={handleEditSubmit}
              type="submit"
              size="small"
              variant="contained"
              sx={{ fontSize: "0.6rem" }}
            >
              Submit
            </LoadingButton>
          </DialogActions>
        </DialogContent>
      </Dialog>
      {openSnackbar && <SnackbarMsg apiMsg={apiMsg} open={openSnackbar} color={MsgColor} />}
    </>
  );
};

export default EditRefusedInvoice;
